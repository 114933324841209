import { prop } from 'datx';
import { getModelMeta } from 'datx-jsonapi';
import { Resource } from 'datx-jsonapi-react';
import { computed } from 'mobx';

import { ChatState } from '@/enums/ChatState';
import { IHighlightedResultsMatch } from '@/interfaces/IHighlightedResultsMatch';
import { IPosterDocument } from '@/interfaces/IPosterDocument';
import { LectureAccessPermissions } from '@/resources/LectureAccessPermissions';
import { LectureStatistic } from '@/resources/LectureStatistic';
import { ThinEventSession } from '@/resources/ThinEventSession';
import { PackageType } from '@/types/PackageType';

import { Tag } from './Tag';
import { ThinEvent } from './ThinEvent';
import { ThinProfile } from './ThinProfile';

interface IDownloadMaterial {
  url: string;
  filename: string;
  size: number;
}

export interface IHighlightedSpeaker {
  lastName: IHighlightedResultsMatch;
  firstName: IHighlightedResultsMatch;
}

export interface ILectureHighlightedResults {
  event: {
    name: IHighlightedResultsMatch;
  };
  speakers: Array<IHighlightedSpeaker>;
  titleEn: IHighlightedResultsMatch;
  title?: IHighlightedResultsMatch;
  eventSession: {
    name: IHighlightedResultsMatch;
  };
}

interface ILectureMetadata {
  highlightResult?: ILectureHighlightedResults;
}

const formatLectureMetadata = (metadata: ILectureMetadata): ILectureHighlightedResults => {
  const { highlightResult } = metadata;

  if (!highlightResult) {
    return undefined;
  }

  return {
    titleEn: highlightResult.titleEn || highlightResult.title,
    speakers: highlightResult.speakers,
    event: highlightResult.event,
    eventSession: highlightResult.eventSession,
  };
};

export class ThinLecture extends Resource {
  public static type = 'thin_lectures';

  @prop
  public title: string;

  @prop
  public doi: string;

  @prop
  public videoDoi: string;

  @prop
  public eventId: string;

  @prop
  public package: PackageType;

  @prop
  public playlist: string;

  @prop
  public abstract: string;

  @prop
  public aiSummary: string;

  @prop
  public aiInsights: string;

  @prop
  public aiQuestionsAnswers: string;

  @prop
  public synopsis: string;

  @prop
  public heldAt?: string;

  @prop
  public publishedAt?: string;

  @prop
  public library?: boolean;

  @prop
  public visible?: boolean;

  @prop
  public posterDocument?: IPosterDocument;

  @prop
  public originalPosterUrl: string;

  @prop
  public largePosterUrl: string;

  @prop
  public mediumPosterUrl: string;

  @prop
  public webpPosterUrl: string;

  @prop
  public smallPosterUrl: string;

  @prop
  public thumbnailPosterUrl: string;

  @prop
  public preloaderPosterUrl: string;

  @prop
  public gatherTownUrl: string;

  @prop
  public originalPosterDocumentThumbnailUrl: string;

  @prop
  public originalPosterDocumentThumbnailExtractUrl: string;

  @prop
  public thumbnailPosterDocumentThumbnailUrl: string;

  @prop
  public thumbnailPosterDocumentThumbnailExtractUrl: string;

  @prop
  public preloaderPosterDocumentThumbnailUrl: string;

  @prop
  public preloaderPosterDocumentThumbnailExtractUrl: string;

  // we use extracts only as a fallback to real thumbnails!

  /**
   * user uploaded image
   */
  @prop
  public largePosterDocumentThumbnailUrl: string;

  /**
   * first page of the pdf as an image
   */
  @prop
  public largePosterDocumentThumbnailExtractUrl: string;

  /**
   * user uploaded image
   */
  @prop
  public mediumPosterDocumentThumbnailUrl: string;

  /**
   * first page of the pdf as an image
   */
  @prop
  public mediumPosterDocumentThumbnailExtractUrl: string;

  /**
   * user uploaded image
   */
  @prop
  public smallPosterDocumentThumbnailUrl: string;

  /**
   * first page of the pdf as an image
   */
  @prop
  public smallPosterDocumentThumbnailExtractUrl: string;

  @prop
  public slug: string;

  @prop
  public slideshow: IDownloadMaterial;

  @prop
  public paper: IDownloadMaterial;

  @prop
  public paperLink: string;

  @prop
  public slideshowUrl: string;

  @prop
  public paperUrl: string;

  @prop
  public posterLecture: boolean;

  @prop
  public chatState: ChatState;

  @prop
  public twilioQuestionsChannel: string;

  @prop
  public videoStartedCount: number;

  @prop
  public commentsCount: number;

  @prop
  public rocketchatUrl: string;

  @prop.toMany('thin_profiles')
  public sortedProfiles: Array<ThinProfile>;

  @prop.toOne('tags')
  public tag: Tag;

  @prop.toOne('thin_event_sessions')
  public eventSession: ThinEventSession;

  @prop
  public highlightedColor: string;

  @prop
  public occurrenceMatchColor: string;

  @computed
  public get linkSlug() {
    return `${this.id}-${this.slug}`;
  }

  @computed
  public get metadata() {
    const metadata = getModelMeta(this);

    return formatLectureMetadata(metadata as ILectureMetadata);
  }

  @computed
  public get posterThumbnailUrl() {
    return this.posterLecture
      ? this.mediumPosterDocumentThumbnailUrl || this.mediumPosterDocumentThumbnailExtractUrl
      : this.mediumPosterUrl;
  }

  @prop.toOne('thin_events')
  public event: ThinEvent;

  @computed
  public get lectureAccessPermission() {
    // eslint-disable-next-line prefer-destructuring
    const collection = this.meta?.collection;

    if (collection) {
      return collection.findOne(LectureAccessPermissions, this.id).restricted;
    }

    return null;
  }

  @computed
  public get statistics(): LectureStatistic | null {
    // eslint-disable-next-line prefer-destructuring
    const collection = this.meta?.collection;

    if (collection) {
      return collection.findOne(LectureStatistic, this.id);
    }

    return null;
  }
}
